import React, { useState } from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import "./header.scss";
import { toggleBasket } from "./basket";
import { useSelector } from "react-redux";
import { BasketState } from "./../state/basket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingBasket,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

export let toggleHeader: () => void;

export const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  toggleHeader = () => {
    setIsOpen(!isOpen);
  };

  const basketQuantity = useSelector<{ basket: BasketState }, number>(state =>
    state.basket.items.reduce((total, item) => (total += item.quantity), 0)
  );

  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "fordandsons.png" }) {
            childImageSharp {
              fixed(width: 29) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <header>
          <nav className="navbar nav is-primary">
            <div className="container">
              <div className="navbar-start">
                <div className="navbar-brand">
                  <Link to="/" className="navbar-item">
                    <Img
                      fixed={data.logo.childImageSharp.fixed}
                      alt="Ford &amp; sons meat shop"
                    />
                  </Link>
                </div>
              </div>
              <div className={`navbar-end ${isOpen ? " is-active" : ""}`}>
                <a
                  href="#"
                  className="navbar-item basket-button"
                  onClick={e => {
                    e.preventDefault();
                    toggleBasket();
                  }}
                >
                  Basket
                  <FontAwesomeIcon
                    className="fa-icon is-circle"
                    icon={faShoppingBasket}
                  />
                  {basketQuantity >= 1 ? (
                    <span className="header-basket-item-floater">
                      {basketQuantity}
                    </span>
                  ) : null}
                </a>
                <a
                  href="tel:07985352114"
                  className="navbar-item is-phone-number"
                >
                  <FontAwesomeIcon
                    className="fa-icon is-circle"
                    icon={faPhoneAlt}
                  />
                  07985 352114
                </a>
              </div>
            </div>
          </nav>
        </header>
      )}
    />
  );
};
