import React, { useEffect, useState } from "react";
import { displayItemUnit } from "./menu";
import "./basket.scss";
import { Price, priceString } from "./price";
import { useSelector, useDispatch } from "react-redux";
import {
  BasketAction,
  BasketState,
  BasketItemHashed,
  removeItemFromBasket,
} from "./../state/basket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const telephoneRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
const postcodeRegex = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

const encode = (data: { [s: string]: any }): string =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

type Basket = {
  items: BasketItemHashed[];
};

export let toggleBasket;

export const Basket = () => {
  const [isOpen, toggleOpen] = useState<boolean>(false);

  const basket = useSelector<{ basket: BasketState }, Basket>(
    state => state.basket
  );
  const dispatch = useDispatch();

  toggleBasket = () => toggleOpen(!isOpen);

  return (
    <>
      <div
        className={`overlay${isOpen ? " is-open" : ""}`}
        onClick={e => {
          e.preventDefault();
          toggleBasket();
        }}
      ></div>
      <div id="basket" className={isOpen ? "is-open" : ""}>
        <div className="">
          <a
            href="#"
            className="close-button"
            onClick={e => {
              e.preventDefault();
              toggleBasket();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </a>
          <h6 className="title is-box box-full">Basket</h6>
        </div>
        <div className="basket-content">
          <div>
            <div className="clear-button">
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  dispatch({ type: BasketAction.RESET }); // TODO add are you sure alert
                }}
              >
                Clear Basket
              </a>
            </div>
            <BasketItems items={basket.items} />
          </div>
          <BasketForm total={calculateCost(basket.items)} order={basket} />
        </div>
      </div>
    </>
  );
};

const BasketItems = (props: { items: BasketItemHashed[] }) => {
  const dispatch = useDispatch();

  return (
    <table className="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>Item</th>
          <th>Quantity</th>
          <th>Price</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.items.map(item => (
          <tr className="basket-item" key={`${item.hash}-basket-item`}>
            <td>
              {item.name} {displayItemUnit(item)}
              {item.selectedOptions ? (
                <div className="small-details">
                  {item.selectedOptions.map(selected => (
                    <small>{selected}</small>
                  ))}
                </div>
              ) : null}
            </td>
            <td>{item.quantity}</td>
            <td>
              {typeof item.pricePerUnit === "string" ? (
                item.pricePerUnit
              ) : (
                <Price price={item.pricePerUnit * item.quantity} />
              )}
            </td>
            <td>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  dispatch(removeItemFromBasket(item.hash));
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

type ValidationType = { [s in "name" | "postcode" | "telephone"]?: string };

const BasketForm = (props: { total: number; order: Basket }) => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [telephone, setTelephone] = useState<string | undefined>(undefined);
  const [postcode, setPostcode] = useState<string | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<ValidationType>({});
  const [submissionResult, setSubmissionResult] = useState<
    string | null | true
  >(null);

  const hasValidationErrors =
    Object.values(validationErrors).filter(t => typeof t !== "undefined")
      .length >= 1;

  const fieldsSet =
    [name, telephone, postcode].filter(field => typeof field !== "undefined")
      .length === 3;

  const order: string = props.order.items
    .map(
      item =>
        `${item.quantity} x ${item.name} ${displayItemUnit(item)} ----- £${
          typeof item.pricePerUnit === "string"
            ? item.pricePerUnit
            : priceString(item.pricePerUnit * item.quantity)
        }`
    )
    .join("\n\r\n");

  console.log("order", order);

  const handleSubmit = e => {
    setSubmissionResult(null);
    e.preventDefault();

    if (hasValidationErrors) {
      return;
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "order", name, telephone, postcode, order }),
    })
      .then(() => {
        setSubmissionResult(true);
      })
      .catch(() => {
        setSubmissionResult(
          "There was an error sending your message, please try again!"
        );
      });
  };

  useEffect(() => {
    if (!name) {
      return;
    }
    if (name.length <= 2) {
      setValidationErrors({
        ...validationErrors,
        name: "Please enter more than 2 characters",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        name: undefined,
      });
    }
  }, [name]);

  useEffect(() => {
    if (!telephone) {
      return;
    }

    if (!telephoneRegex.test(telephone)) {
      setValidationErrors({
        ...validationErrors,
        telephone: "Please enter a valid telephone number",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        telephone: undefined,
      });
    }
  }, [telephone]);

  useEffect(() => {
    if (!postcode) {
      return;
    }

    if (!postcodeRegex.test(postcode)) {
      setValidationErrors({
        ...validationErrors,
        postcode: "Please enter a valid postcode",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        postcode: undefined,
      });
    }
  }, [postcode]);

  return (
    <div className="basket-form">
      <div className="basket-total">
        <div className="text">Total</div>
        <div className="text-total">
          <Price price={props.total} forceDecimal={true} />{" "}
          {hasMP(props.order.items) ? " and m/p" : null}
        </div>
        <hr />
      </div>
      <div className="content">
        <p>Please fill out the form below for collection.</p>
      </div>
      <form name="order" onSubmit={handleSubmit}>
        <input name="order" type="hidden" value={order} />
        <input type="hidden" name="form-name" value="order" />
        <FormElement
          name="name"
          placeholder="Please enter your name"
          label="Your Name"
          setValue={setName}
          value={name}
          validation={validationErrors.name}
        />
        <FormElement
          name="telephone"
          placeholder="Please enter your contact number"
          label="Telephone"
          setValue={setTelephone}
          value={telephone}
          validation={validationErrors.telephone}
        />
        <FormElement
          name="postcode"
          placeholder="Please enter your postcode"
          label="Postcode"
          setValue={setPostcode}
          value={postcode}
          validation={validationErrors.postcode}
        />
        <div className="content">
          <p>Next day collection if you order before 5pm</p>
        </div>
        <div className="field">
          <div className="control">
            <input
              disabled={props.total === 0 || hasValidationErrors || !fieldsSet}
              className="button is-primary is-fullwidth"
              name="submit"
              type="submit"
              value="Order for Colleciton"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const hasMP = (items: BasketItemHashed[]): boolean =>
  items.map(item => typeof item.pricePerUnit).includes("string");

const calculateCost = (items: BasketItemHashed[]): number =>
  items.reduce((total, item) => {
    return typeof item.pricePerUnit === "number"
      ? (total += item.pricePerUnit * item.quantity)
      : total;
  }, 0);

const FormElement = (props: {
  name: string;
  value: any;
  setValue: (value: any) => void;
  validation?: string;
  placeholder?: string;
  label?: string;
}) => (
  <div className="field">
    <div className="control">
      {props.label && <label className="label">{props.label}</label>}
      <input
        className={`input${props.validation ? " is-danger" : ""}`}
        name={props.name}
        type="text"
        value={props.value || ""}
        placeholder={props.placeholder}
        onChange={e => {
          props.setValue(e.target.value);
        }}
      />
      {props.validation && <p className="help is-danger">{props.validation}</p>}
    </div>
  </div>
);
