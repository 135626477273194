import React, { useState } from "react";
import * as data from "./../../data.json";
import Masonry from "react-masonry-css";
import "./menu.scss";
import { useDispatch, useSelector } from "react-redux";
import { BasketAction, BasketState } from "../state/basket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Price } from "./price";
import { OptionModalActions } from "./../state/option.modal";

export type MenuOptions = {
  name: string;
  options: string[];
};

export type MenuItemType = {
  name: string;
  pricePerUnit: number | string;
  unit: string;
  interval: number;
  options?: MenuOptions[];
  description?: string;
  from?: boolean;
};

export type MenuItemTitleType = {
  title: string;
};

export type MenuBlock = {
  title: string;
  items: (MenuItemType | MenuItemTitleType)[];
  description?: string;
  smallDescription?: string;
  color?: string;
};

export type Menu = {
  blocks: MenuBlock[];
};

export const isMenuItem = (
  item: MenuItemType | MenuItemTitleType
): item is MenuItemType => item.hasOwnProperty("name");

export const MenuBlock = (props: { block: MenuBlock }) => {
  const [active, setActive] = useState<boolean>(false);
  return (
    <div className={`menu-block${active ? " is-active" : ""}`}>
      <div
        className="menu-block-title"
        onClick={() => {
          setActive(!active);
        }}
      >
        <h2 className="title">{props.block.title}</h2>
      </div>
      <div className="menu-block-content">
        {props.block.description && (
          <p className="menu-description">{props.block.description}</p>
        )}
        {props.block.smallDescription && (
          <p className="menu-description is-size-7">
            {props.block.smallDescription}
          </p>
        )}
        <table className="table is-fullwidth is-striped">
          <tbody>
            {props.block.items.map(item =>
              isMenuItem(item) ? (
                <MenuItem key={Object.values(item).join("-")} item={item} />
              ) : (
                <MenuTitle key={`${item.title}-title`} title={item.title} />
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const MenuTitle = (props: { title: string }) => (
  <tr>
    <th className="menu-title-item" colSpan={4}>
      {props.title}
    </th>
  </tr>
);

export const MenuItem = (props: { item: MenuItemType }) => {
  const dispatch = useDispatch();
  const itemCount: number = useSelector<{ basket: BasketState }, number>(
    state => {
      return state.basket && state.basket.items
        ? state.basket.items.reduce(
            (quantity, stateItem) =>
              stateItem.name === props.item.name
                ? (quantity += stateItem.quantity)
                : quantity,
            0
          ) || 0
        : 0;
    }
  );
  return (
    <>
      <tr className="menu-item">
        <td>{props.item.name}</td>
        <td>{displayItemUnit(props.item)}</td>
        <td>
          {props.item.from && "From"}
          {typeof props.item.pricePerUnit === "string" ? (
            props.item.pricePerUnit
          ) : (
            <Price price={props.item.pricePerUnit} />
          )}
        </td>
        <td>
          <a
            href="#"
            className="button is-primary"
            onClick={e => {
              e.preventDefault();

              if (props.item.options) {
                dispatch({
                  type: OptionModalActions.OPEN,
                  item: props.item,
                });

                return;
              }

              dispatch({
                type: BasketAction.ADD,
                item: {
                  ...props.item,
                  quantity: 1,
                },
              });
            }}
          >
            {itemCount >= 1 && (
              <span className="menu-float-item">{itemCount}</span>
            )}
            <FontAwesomeIcon icon={faPlus} />
          </a>
        </td>
      </tr>
      {props.item.description && (
        <tr>
          <td colSpan={4}>
            {props.item.description.split("\r\n\n").map(text => (
              <p>{text}</p>
            ))}
          </td>
        </tr>
      )}
    </>
  );
};

export const displayItemUnit = (item: MenuItemType): string =>
  `${item.unit.length <= 2 ? item.interval : ""} ${item.unit}`;

export const MenuComponent = () => (
  <Masonry
    breakpointCols={{
      default: 4,
      1440: 3,
      700: 2,
      500: 1,
    }}
    className="product-menu"
    columnClassName="product-menu--grid-column"
  >
    {(data as Menu).blocks.map(block => (
      <MenuBlock key={block.title} block={block} />
    ))}
  </Masonry>
);
