import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BasketAction } from "../state/basket";
import { OptionModalActions, OptionModalState } from "./../state/option.modal";
import { MenuItemType } from "./menu";
import "./optionModal.scss";

export const OptionModal = () => {
  const item = useSelector<
    { optionModal: OptionModalState },
    MenuItemType | undefined
  >(state => state.optionModal.item);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState<{
    [s: string]: string | undefined;
  }>({});

  const isOpen = typeof item !== "undefined";

  const choiceOptions = (item: MenuItemType, selectedOptions: string[]) => {
    dispatch({
      type: BasketAction.ADD,
      item: {
        ...item,
        selectedOptions,
      },
    });

    setSelectedOptions({});

    dispatch({
      type: OptionModalActions.CLOSE,
    });
  };

  const allSelected =
    item?.options?.length !==
    Object.values(selectedOptions).filter(op => typeof op !== "undefined")
      .length;

  return (
    <div className={`modal${isOpen ? " is-active" : ""}`}>
      <div
        className={`modal-background`}
        onClick={e => {
          e.preventDefault();
          dispatch({
            type: OptionModalActions.CLOSE,
          });
        }}
      ></div>
      <div className={`modal-card`}>
        <a
          href="#"
          className="modal-close"
          onClick={e => {
            e.preventDefault();
            dispatch({
              type: OptionModalActions.CLOSE,
            });
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
        <div className="modal-card-head">
          <div className="modal-card-title">
            <h4 className="title">Choose an option</h4>
          </div>
        </div>
        <div className="modal-card-body">
          <h5 className="subtitle">{item?.name}</h5>
          {item?.options?.map(choice => (
            <div className="field">
              <div className="control">
                <label className="label">{choice.name}</label>
                <select
                  className="select"
                  name={choice.name}
                  onChange={e => {
                    e.preventDefault();

                    const option =
                      e.target.selectedIndex === 0
                        ? undefined
                        : choice.options[e.target.selectedIndex - 1];

                    setSelectedOptions({
                      ...selectedOptions,
                      [choice.name]: option,
                    });
                  }}
                >
                  <option>Select from the following options</option>
                  {choice.options.map(option => (
                    <option value={option}>{option}</option>
                  ))}
                </select>
              </div>
            </div>
          ))}
          <button
            className="is-primary button"
            disabled={allSelected}
            onClick={() => {
              if (allSelected) {
                // TODO validation errors
                return;
              }
              choiceOptions(
                item as MenuItemType,
                Object.keys(selectedOptions).map(
                  key => `${key} - ${selectedOptions[key]}`
                )
              );
            }}
          >
            Add to Basket
          </button>
        </div>
      </div>
    </div>
  );
};
