import React from "react";

const formatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const formatterWithoutDecimal = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const Price = (props: { price: number; forceDecimal?: boolean }) => {
  return <>{priceString(props.price, props.forceDecimal)}</>;
};

export const priceString = (price: number, forceDecimal?: boolean): string => {
  const priceFormatter =
    forceDecimal || price % 1 !== 0 ? formatter : formatterWithoutDecimal;
  return priceFormatter.format(price);
};
