import React from "react";
import "./../index.scss";
import {
  Header,
  Footer,
  MenuComponent,
  Basket,
  OptionModal,
} from "./../components";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import settings from "./../../settings.json";
import Img from "gatsby-image";

export default ({ data }) => {
  return (
    <>
      <Helmet>
        <title>{settings.title}</title>
        <meta name="description" content={settings.description} />
        <meta name="keywords" content={settings.keywords.join(", ")} />
      </Helmet>
      <Header />
      <Basket />
      <div className="hero is-small is-desktop-only">
        <div className="hero-body">
          <div className="columns">
            <div className="column">
              <div className="black-box">
                <h3 className="title">Free Local Delivery</h3>
                <h4 className="title">3 times a week</h4>
              </div>
            </div>
            <div className="column">
              <div className="black-box">
                <h3 className="title">Open 7 Days</h3>
                <h4 className="title">a week</h4>
              </div>
            </div>
            <div className="column">
              <div className="black-box">
                <h3 className="title">Pre-order your</h3>
                <h4 className="title">in store collection</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column">
              <h4 className="title is-box">About Us</h4>
              <div className="content">
                <p>{settings.description}</p>
              </div>
            </div>
            <div className="column">
              <Img
                fixed={data.van.childImageSharp.fixed}
                alt="Ford &amp; sons meat shop"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column">
              <Img
                fixed={data.meatplatter.childImageSharp.fixed}
                alt="Ford &amp; sons meat shop"
              />
            </div>
            <div className="column">
              <Img
                fixed={data.steak.childImageSharp.fixed}
                alt="Ford &amp; sons meat shop"
              />
            </div>
            <div className="column">
              <Img
                fixed={data.assorted.childImageSharp.fixed}
                alt="Ford &amp; sons meat shop"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h1>MAP</h1>
            </div>
            <div className="column">
              <h1 className="title is-box">Where to find us</h1>
              <p>Wheatley Farm Nursery</p>
              <p>London Road</p>
              <p>Rayleigh</p>
              <p>SS6 9ES</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h4 className="title is-box">Click &amp; Collect</h4>
          <div className="content">
            <p>
              Select from the full range of our products. Be sure to order
              before 5pm for next day collection!
            </p>
          </div>
          <MenuComponent />
        </div>
      </section>
      <OptionModal />
      <Footer />
    </>
  );
};

export const query = graphql`
  query {
    meatplatter: file(relativePath: { eq: "meat.platter.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    steak: file(relativePath: { eq: "steak.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    van: file(relativePath: { eq: "van.png" }) {
      childImageSharp {
        fixed(width: 523) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    assorted: file(relativePath: { eq: "assorted.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
