import React from "react";
import { Link } from "gatsby";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

export const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "fordandsons-100.png" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <footer className="footer is-primary">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <Link to="/" className="">
                <Img
                  fixed={data.logo.childImageSharp.fixed}
                  alt="Ford &amp; sons meat shop"
                />
              </Link>
            </div>
            <div className="level-right">
              <a className="footer-phone" href="tel:07985352114">
                <FontAwesomeIcon className="fa-icon" icon={faPhoneAlt} /> 07985
                352114
              </a>
            </div>
          </div>
          <ul>
            <li>
              <a href="">
                <FontAwesomeIcon className="fa-icon" icon={faFacebookF} />{" "}
                /Fordandsonsmeatsuppliers
              </a>
            </li>
            <li>
              <a href="">
                <FontAwesomeIcon className="fa-icon" icon={faInstagram} />{" "}
                Fordandsons_meatsuppliers
              </a>
            </li>
            <li>
              <a href="mailto:fordandsonsmeatshoprayleigh@gmail.com">
                <FontAwesomeIcon className="fa-icon" icon={faEnvelope} />{" "}
                fordandsonsmeatshoprayleigh@gmail.com
              </a>
            </li>
          </ul>
        </div>
      </footer>
    )}
  />
);
